<template>
  <div class="row match-details">
    <div class="col-12 text-center">
      <h6 class="text-uppercase">{{$tc('round', 1) | toCapitalize}} {{match.round}}</h6>
      <h4>
        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: match.team_local_slug } }">{{ match.team_local_name }}</router-link>
         - 
        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: match.team_visit_slug } }">{{ match.team_visit_name }}</router-link>
      </h4>
    </div>
    <div class="col-6 team-local">
      <img :src="match.team_local_image" :alt="match.team_local_name" class="img-fluid">
      <span class="results">{{ match.local_goals }}</span>
    </div>
    <div class="col-6 team-visit">
      <span class="results">{{ match.visit_goals }}</span>
      <img :src="match.team_visit_image" :alt="match.team_visit_name" class="img-fluid">
    </div>
    <div class="col-12 col-lg-6 text-center">
      <hr>
      <h5>
        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: match.team_local_slug } }">{{ match.team_local_name }}</router-link>
      </h5>
      <hr>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>{{ $t('player') }}</th>
              <th>{{ $t('position') }}</th>
              <th>
                <img src="../../../assets/images/yellow.png" alt="Amarillas" class="img-icon-sm">
              </th>
              <th>
                <img src="../../../assets/images/red.png" alt="Rojas" class="img-icon-sm">
              </th>
              <th>
                <img src="../../../assets/images/assist.png" alt="Assistencias" class="img-icon-sm">
              </th>
              <th>
                <img src="../../../assets/images/goals.png" alt="Goles" class="img-icon-sm">
              </th>
              <th>
                <img src="../../../assets/images/best.png" alt="Mejor jugador" class="img-icon-sm">
              </th>
              <th>{{ $t('pj') }}</th>
              <th>{{ $t('gc') }}</th>
              <th>{{ $tc('point', 2) | toCapitalize }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(player, ind) in match.players_local" :key="`local-${ind}`">
              <td class="text-left">{{ player.nick }}</td>
              <td>{{ player.position | completePosition }}</td>
              <td>{{ player.yellow_card }}</td>
              <td>{{ player.red_card }}</td>
              <td>{{ player.assist }}</td>
              <td>{{ player.goal }}</td>
              <td>{{ player.best }}</td>
              <td>{{ player.pj | hideZero }}</td>
              <td>{{ player.gc | hideZero }}</td>
              <td>{{ player.points | hideZero }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr v-if="match.local_images">
      <b-carousel v-if="match.local_images" id="carousel-1" :interval="5000" controls indicators>
        <b-carousel-slide v-for="(image, ind) in match.local_images" :key="`img-${ind}`" :img-src="image.link" :caption="image.name"></b-carousel-slide>
      </b-carousel>
      <hr v-if="match.local_videos">
      <div class="mb-3" v-for="(video, ind) in match.local_videos" :key="`local-video-${ind}`">
        <h6 class="text-left">{{ video.name }}</h6>
        <iframe width="100%" height="400" :src="`https://www.youtube.com/embed/${video.link}`" frameborder="0" allowfullscreen allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
      </div>
    </div>
    <div class="col-12 col-lg-6 text-center">
      <hr>
      <h5>
        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: match.team_visit_slug } }">{{ match.team_visit_name }}</router-link>
      </h5>
      <hr>
      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>{{ $t('player') }}</th>
              <th>{{ $t('position') }}</th>
              <th>
                <img src="../../../assets/images/yellow.png" alt="Amarillas" class="img-icon-sm">
              </th>
              <th>
                <img src="../../../assets/images/red.png" alt="Rojas" class="img-icon-sm">
              </th>
              <th>
                <img src="../../../assets/images/assist.png" alt="Assistencias" class="img-icon-sm">
              </th>
              <th>
                <img src="../../../assets/images/goals.png" alt="Goles" class="img-icon-sm">
              </th>
              <th>
                <img src="../../../assets/images/best.png" alt="Mejor jugador" class="img-icon-sm">
              </th>
              <th>{{ $t('pj') }}</th>
              <th>{{ $t('gc') }}</th>
              <th>{{ $tc('point', 2) | toCapitalize }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(player, ind) in match.players_visit" :key="`visit-${ind}`">
              <td class="text-left">{{ player.nick }}</td>
              <td>{{ player.position | completePosition }}</td>
              <td>{{ player.yellow_card }}</td>
              <td>{{ player.red_card }}</td>
              <td>{{ player.assist }}</td>
              <td>{{ player.goal }}</td>
              <td>{{ player.best }}</td>
              <td>{{ player.pj | hideZero }}</td>
              <td>{{ player.gc | hideZero }}</td>
              <td>{{ player.points | hideZero }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr v-if="match.visit_images">
      <b-carousel v-if="match.visit_images" id="carousel-1" :interval="5000" controls indicators>
        <b-carousel-slide v-for="(image, ind) in match.visit_images" :key="`img-visit-${ind}`" :img-src="image.link" :caption="image.name"></b-carousel-slide>
      </b-carousel>
      <hr v-if="match.visit_videos">
      <div class="mb-3" v-for="(video, ind) in match.visit_videos" :key="`local-video-${ind}`">
        <h6 class="text-left">{{ video.name }}</h6>
        <iframe width="100%" height="400" :src="`https://www.youtube.com/embed/${video.link}`" frameborder="0" allowfullscreen allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      match: {},
      slug: this.$route.params.divisionSlug
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'divisionFront'
    ])
  },
  filters: {
    hideZero(value) {
      if (value === 0) {
        return '';
      }
      return value;
    }
  },
  mounted () {
    this.$store.dispatch('SET_MENU_PRIMARY_DIVISION', '')
    this.fetchData()
  },
  methods: {
    fetchData () {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.divisionSlug
      const fixtureId = this.$route.params.fixtureId
      const path = `${lang}/console/${console}/division/${slug}/match/${fixtureId}`
      this.$axios.get(path).then((response) => {
        this.match = response.data.data
      })
    }
  }
}
</script>
